import React, {useState} from 'react';
import {isMobile} from "react-device-detect";
import useWindow from "../hooks/useWindow";
import {StaticImage} from "gatsby-plugin-image";
import "../sass/test.scss";
import Layout from "../_components/layout/layout";


const Test = ({style}: { style?: any }) => {
    const [isCurrent, setCurrent] = useState(isMobile);
    useWindow(() => {
        window.onresize = () => setCurrent(isMobile);
    }, [isMobile])
    return (
        <Layout>
            <section style={style} className={`lmntl-section about ${isCurrent && "mobile"} `}>
                <div className="lmntl-text-content">
                    <div className="lmntl-container">
                        <h2>
                            About us
                        </h2>
                        <p>
                            We are Matt and Leslie and we are on a collective journey of discovery and becoming,
                            filtering our decision making through our collective values of love and vision.
                            Based on our lived experience, we believe in autonomy and self-love as paramount
                            to partnership in work, life, and the collective human experience in community with
                            others. As individuals, we are doing the work to create a life that sustains our physical,
                            emotional and spiritual selves in alignment with our true north, and as a unit,
                            we support one another in our ongoing journey of healing. Living in our element looks
                            like physical minimalism and emotional and spiritual expansiveness.
                            We are passionate about our daily becoming, and we find our purpose and fulfillment
                            in sharing our experiences with others on this challenging and beautiful journey of discovery.
                            Success to us is a by-product of living in your true elemental nature.
                            We are grateful for the opportunity to meet you and walk this path together.
                        </p>
                    </div>
                </div>
                <div className="lmntl-image-content">
                    <StaticImage src={"../static/RAS_5144.png"} alt={"Sitting together smiling"}/>
                </div>
                <div className="spacer"/>
            </section>
        </Layout>
    );
};

export default Test;
